export default function Toppic() {
    return (
        <>
            <div className='px-4'>
                <img
                    src="./background.png"
                    className='w-full'
                    alt='dashboard'
                />
            </div>
        </>
    )
}