import React, { useState, useEffect } from "react";
import BarChart from "../components/BarChart";
import Bottomtitle from "../components/Bottomtitle";
import Sidebarhandle from "../components/Sidebarhandle";
import Toppic from "../components/Toppic";
import Papa from 'papaparse';
import DropdownCompare from "../components/DropdownCompare";
import DropdownCompare2 from "../components/DropdownCompare2";

const CSV_FILE_MAP = {
    Arroz: ["0", "0", "0", "0"],
    Aceite: ["1", "1", "1", "1"],
    Leche: ["2", "2", "2", "2"],
    Detergente: ["3", "3", "3", "3"],
};

export default function Compare() {
    const siteNames = ['Makro * 1000', 'Olimpica', 'D1', 'Exito'];
    const productItems = ['Arroz', 'Aceite', 'Leche', 'Detergente'];
    const productItemser = ['Kg', 'L'];
    
    const [priceStats, setPriceStats] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedProducter, setSelectedProducter] = useState('');
    const [csvFiles, setCSVFiles] = useState({});
    const [loading, setLoading] = useState(false);

    const baseUrl = "/data/";

    useEffect(() => {
        if (selectedProduct) {
            const indices = CSV_FILE_MAP[selectedProduct];
            setCSVFiles({
                Olimpica: [`${baseUrl}olimpica_${indices[0]}.csv`],
                'Makro * 1000': [`${baseUrl}makro_${indices[1]}.csv`],
                D1: [`${baseUrl}D1_${indices[2]}.csv`],
                Exito: [`${baseUrl}exito_${indices[3]}.csv`],
            });
        }
    }, [selectedProduct]);

    const fetchCSVData = async (file) => {
        const response = await fetch(file);
        if (!response.ok) {
            throw new Error(`Failed to fetch ${file}: ${response.statusText}`);
        }
        const text = await response.text();
        return text;
    };

    const parseCSVData = (data) => {
        return new Promise((resolve, reject) => {
            Papa.parse(data, {
                header: true,
                complete: (results) => resolve(results.data),
                error: (error) => reject(error),
            });
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!selectedProduct) return;

            setLoading(true);
            const stats = {};
            for (const site of siteNames) {
                const files = csvFiles[site];
                if (!files || files.length === 0) {
                    console.error(`No files found for site: ${site}`);
                    continue;
                }

                let allPrices = [];

                for (const file of files) {
                    try {
                        // Fetch and then parse the CSV data
                        const csvText = await fetchCSVData(file);
                        const data = await parseCSVData(csvText);

                        const prices = data.map(row => {
                            const priceValue = row.Price;
                            if (site === "Makro * 1000") {
                                if (selectedProducter === 'L') {
                                    return priceValue ? parseFloat(priceValue.replace(/[$,]/g, '')/2100) : null;                                     
                                } else {
                                    return priceValue ? parseFloat(priceValue.replace(/[$,]/g, '')/1000) : null;     
                                }
                            } else {
                                if (selectedProducter === 'L') {
                                    return priceValue ? parseFloat(priceValue.replace(/[$,]/g, '')/1) : null;                                     
                                } else {
                                    return priceValue ? parseFloat(priceValue.replace(/[$,]/g, '')/2.1) : null;     
                                }
                                                               
                            }
                        }).filter(price => price !== null);

                        allPrices.push(...prices);
                        
                    } catch (error) {
                        console.error(`Error fetching or parsing data from ${file}:`, error);
                    }
                }

                if (allPrices.length > 0) {
                    stats[site] = {
                        highest: Math.max(...allPrices),
                        lowest: Math.min(...allPrices),
                        average: (allPrices.reduce((sum, price) => sum + price, 0) / allPrices.length).toFixed(2),
                    };
                }
            }

            setPriceStats(stats);
            setLoading(false);
        };
        
        fetchData();
    }, [selectedProduct,selectedProducter, csvFiles]);

    return (
        <div className="flex w-screen">
            <Sidebarhandle />
            <div className="w-full 2xl:w-4/5 2xl:ms-0 ms-[5%] bg-[#EFEFEF] min-h-screen">
                <div className='p-5 px-8'>
                    <Toppic />
                    <div className="p-4 flex gap-4">
                        <DropdownCompare
                            items={productItems}
                            selectedItem={selectedProduct}
                            onSelect={setSelectedProduct}
                        />
                        <DropdownCompare2
                            items={productItemser}
                            selectedItem={selectedProducter}
                            onSelect={setSelectedProducter}
                        />
                    </div>
                    <div className="p-4">
                        {loading ? (
                            <p>Loading data...</p>
                        ) : (
                            <BarChart value={priceStats || {}} />
                        )}
                    </div>
                    <Bottomtitle />
                </div>
            </div>
        </div>
    );
}